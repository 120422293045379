import { ControlSource } from '@/station/type/device.js'
import { ECommanderModeLostAction, ERthMode, LostControlActionInCommandFLight, WaylineLostControlActionInCommandFlight } from '@/api/station/stationDock.js'

export const ControlSourceChangeType = Object.freeze({
  "Flight" : 1,
  "Payload" : 2,

  getName : function(code) {
    let result = null;
    Object.entries(this).forEach(([key, value]) => {
      if (value === code) {
        result = key.toString();
      }
    });
    return result;
  },
})

// 제어 메시지 변경
export class ControlSourceChangeInfo {
  constructor(_sn, _type, _control_source) {
    this.sn = _sn
    this.type  = ControlSourceChangeType.getName(_type)
    this.control_source = ControlSource.getName(_control_source);

    if(this.type == null || this.control_source == null) {
      throw new Error("ControlSourceChangeInfo type or control_source is null");
    }
  }
}

// 목표 지점 결과로 비행
export class FlyToPointMessage {
    constructor(_sn, _result, _message) {
        this.sn = _sn
        this.result = _result
        this.message = _message
    }
}

// 원클릭 이륙 결과
export class TakeoffToPointMessage {
    constructor(_sn, _result, _message) {
        this.sn = _sn
        this.result = _result
        this.message = _message
    }
}

// 장치가 drc 모드를 종료합니다.
export class DrcModeExitNotifyMessage {
    constructor(_sn, _result, _message) {
        this.sn = _sn
        this.result = _result
        this.message = _message
    }
}

// 비행 제어 모드 상태
export class DrcStatusNotifyMessage {
    constructor(_sn, _result, _message) {
        this.sn = _sn
        this.result = _result
        this.message = _message
    }
}

export const WaylineLostControlActionInCommandFlightOptions = [
  { label: 'Continue', value: WaylineLostControlActionInCommandFlight.CONTINUE },
  { label: 'Execute Lost Action', value: WaylineLostControlActionInCommandFlight.EXEC_LOST_ACTION }
]

export const LostControlActionInCommandFLightOptions = [
  { label: 'Return Home', value: LostControlActionInCommandFLight.RETURN_HOME },
  { label: 'Hover', value: LostControlActionInCommandFLight.HOVER },
  { label: 'Landing', value: LostControlActionInCommandFLight.Land }
]

export const RthModeInCommandFlightOptions = [
  { label: 'Smart Height', value: ERthMode.SMART },
  { label: 'Setting Height', value: ERthMode.SETTING }
]

export const CommanderModeLostActionInCommandFlightOptions = [
  { label: 'Continue', value: ECommanderModeLostAction.CONTINUE },
  { label: 'Execute Lost Action', value: ECommanderModeLostAction.EXEC_LOST_ACTION }
]

export const CommanderFlightModeInCommandFlightOptions = [
  { label: 'Smart Height', value: ERthMode.SMART },
  { label: 'Setting Height', value: ERthMode.SETTING }
]

// 云台重置模式
export const GimbalResetMode = Object.freeze({
  "Recenter" : 0,
  "Down" : 1,
  "RecenterGimbalPan" : 2,
  "PitchDown" : 3,
})

export const GimbalResetModeOptions = [
  { label: 'Gimbal Recenter', value: GimbalResetMode.Recenter },
  { label: 'Gimbal down', value: GimbalResetMode.Down },
  { label: 'Recenter Gimbal Pan', value: GimbalResetMode.RecenterGimbalPan },
  { label: 'Gimbal Pitch Down', value: GimbalResetMode.PitchDown }
]

export const GimbalKeyCode= Object.freeze({
    "KEY_Z" : 'keyZ', //드론 짐벌 왼쪽 회전
    "KEY_X" : 'keyX', //드론 짐벌 위쪽 회전
    "KEY_C" : 'keyC', //드론 짐벌 아래쪽 회전
    "KEY_V" : 'keyV', //드론 짐벌 오른쪽 회전
});
