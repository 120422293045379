import {
  ref,
  onUnmounted,
  watch,
} from 'vue'
import {
  DRC_METHOD,
} from '@/station/type/drc'
import UseMqtt from './use-mqtt.js'
let myInterval = null;

export const KeyCode= Object.freeze({
  "KEY_R" : 'keyR', //드론 up
  "KEY_F" : 'keyF', //드론 down
  "KEY_Q" : 'keyQ', //드론 좌 회전
  "KEY_E" : 'keyE', //드론 우 회전
  "KEY_W" : 'keyW', //드론 전진
  "KEY_A" : 'keyA', //드론 왼쪽
  "KEY_S" : 'keyS', //드론 후진
  "KEY_D" : 'keyD', //드론 오른쪽
});

export function useManualControl(deviceTopicInfo, isCurrentFlightController) {

  console.log("useManualControl param types ------------------")
  console.log(isCurrentFlightController)
  console.log(deviceTopicInfo)
  console.log("useManualControl param types ------------------")

  if(typeof isCurrentFlightController != "object") {
    return
  }

  if(typeof deviceTopicInfo != "object") {
    return
  }

  const activeCodeKey = ref(null)
  const mqttHooks = new UseMqtt()
  mqttHooks.setStoreMqttState(deviceTopicInfo)
  let seq = 0

  function handlePublish(params) {
    const body = {
      method: DRC_METHOD.DRONE_CONTROL,
      data: params,
    }
    handleClearInterval()
    myInterval = setInterval(() => {
      body.data.seq = seq++
      seq++
      window.console.log('keyCode>>>>', activeCodeKey.value, deviceTopicInfo.pubTopic)
      mqttHooks?.publishMqtt(deviceTopicInfo.pubTopic, body, { qos: 0 })
    }, 50)
  }

  function handleKeyup (keyCode) {
    if (!deviceTopicInfo.pubTopic) {
      console.error("DRC 링크가 설정되었는지 확인하세요.")
      return
    }
    const SPEED = 5 //  check
    const HEIGHT = 5 //  check
    const W_SPEED = 20 // 속도

    seq = 0
    switch (keyCode) {
      case 'KeyA':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ y: -SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyW':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ x: SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyS':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ x: -SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyD':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ y: SPEED })
        activeCodeKey.value = keyCode
        break
      case 'ArrowUp':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ h: HEIGHT })
        activeCodeKey.value = keyCode
        break
      case 'ArrowDown':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ h: -HEIGHT })
        activeCodeKey.value = keyCode
        break
      case 'KeyQ':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ w: -W_SPEED })
        activeCodeKey.value = keyCode
        break
      case 'KeyE':
        if (activeCodeKey.value === keyCode) return
        handlePublish({ w: W_SPEED })
        activeCodeKey.value = keyCode
        break
      default:
        break
    }
  }

  function handleClearInterval () {
    clearInterval(myInterval)
    console.log('handleClearInterval>>>>', myInterval)
    myInterval = null
  }

  function resetControlState () {
    console.log('resetControlState>>>>', activeCodeKey.value)
    activeCodeKey.value = null
    seq = 0
    handleClearInterval()
  }

  function onKeyup () {
    console.log("onKeyup")
    resetControlState()
  }

  function onKeydown (e) {
    console.log("onKeydown", e.code)
    handleKeyup(e.code)
  }

  function startKeyboardManualControl () {
    window.addEventListener('keydown', onKeydown)
    window.addEventListener('keyup', onKeyup)
  }

  function closeKeyboardManualControl () {
    resetControlState()
    window.removeEventListener('keydown', onKeydown)
    window.removeEventListener('keyup', onKeyup)
  }

  watch(() => isCurrentFlightController.value, (val) => {
    if (val && deviceTopicInfo.pubTopic) {
      startKeyboardManualControl()
    } else {
      closeKeyboardManualControl()
    }
  }, { immediate: true })

  onUnmounted(() => {
    closeKeyboardManualControl()
  })

  function handleEmergencyStop () {
    if (!deviceTopicInfo.pubTopic) {
      console.error("DRC 링크가 설정되었는지 확인하세요.")
      return
    }
    const body = {
      method: DRC_METHOD.DRONE_EMERGENCY_STOP,
      data: {}
    }
    resetControlState()
    window.console.log('handleEmergencyStop>>>>', deviceTopicInfo.pubTopic, body)
    mqttHooks?.publishMqtt(deviceTopicInfo.pubTopic, body, { qos: 1 })
  }

  return {
    activeCodeKey,
    handleKeyup,
    handleEmergencyStop,
    resetControlState,
  }
}
