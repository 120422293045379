import EventEmitter from 'eventemitter3'
import {OPTIONS,} from './config.js'

import * as mqtt from "mqtt";

export class UranusMqtt extends EventEmitter {
  _url
  _options
  _client
  _hasInit

  constructor (url, options) {
    super()
    this._url = url || ''
    this._options = options
    this._client = null
    this._hasInit = false
  }

  initMqtt = () => {
    // 한 번만 초기화
    if (this._hasInit) return
    // 연결을 설정하다
    this._client = mqtt.connect(this._url, {
      ...OPTIONS,
      ...this._options,
    })
    this._hasInit = true
    if (this._client) {
      this._client.on('reconnect', this._onReconnect)

      // 消息监听
      this._client.on('message', this._onMessage)

      // 连接关闭
      this._client.on('close', this._onClose)

      // 连接异常
      this._client.on('error', this._onError)
    }
  }

  // 发布
  publishMqtt = (topic, body, opts) => {
    if (!this._client?.connected) {
      this.initMqtt()
    }
    var sendOpts = opts
    if(sendOpts == null) {
      sendOpts = {}
    }

    this._client?.publish(topic, body, sendOpts, (error, packet) => {
      console.info('mqtt publish error,', topic, body, sendOpts)
      console.info('mqtt publish error,', error, packet)
      if (error) {
        console.error('mqtt publish error,', error, packet)
      }
    })
  }

  // 订阅
  subscribeMqtt = (topic) => {
    if (!this._client?.connected) {
      this.initMqtt()
    }
    console.log('subscribeMqtt>>>>>', topic)
    this._client?.subscribe(topic, (error, granted) => {
      console.log('mqtt subscribe,', error, granted)
    })
  }

  // 取消订阅
  unsubscribeMqtt = (topic) => {
    console.log('mqtt unsubscribeMqtt,', topic)
    this._client?.unsubscribe(topic)
  }

  // 关闭 mqtt 客户端
  destroyed = () => {
    console.log('mqtt destroyed')
    this._client?.end()
  }

  _onReconnect = () => {
    if (this._client) { console.error('mqtt reconnect,') }
  }

  _onMessage = (topic, payload, packet) => {
    console.log('mqtt message,', topic, payload, packet)
    if(typeof payload == "object") {
      this.emit('onMessageMqtt', { topic, payload, packet })
    }
  }

  _onClose = () => {
    // 연결이 비정상적으로 종료된 경우 자동으로 다시 연결됩니다.
    console.error('mqtt close')
    this.emit('onStatus', {
      status: 'close',
    })
  }

  _onError = (error) => {
    // 연결 오류가 발생하면 자동으로 다시 연결됩니다.

    console.error('mqtt error,', error)
    this.emit('onStatus', {
      status: 'error',
      data: error,
    })
  }
}
