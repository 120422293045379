
import store from "@/store";
import { fetchStationDecConnect } from '@/api/station/stationDrc.js'
import {UranusMqtt} from '@/station/mqtt/index'

// const StatusOptions = {
//   status: 'close',
//   event: CloseEvent,
// } | {
//   status: 'open',
//   retryCount : null,
// } | {
//   status: 'pending',.0
// }

class UseConnectMqtt {
  mqttState = null

  setDeviceDockInfo(val) {
    // 1.스테이션 연결
    // 2.장치에는 비행 제어 권한이 있습니다
    // 3.mqtt 연결 설정을 위한 인증 정보 요청
    console.log("UseConnectMqtt setDeviceDockInfo",val)
    if (val) {
      if (this.mqttState != null) return

      fetchStationDecConnect({cls : "dji"}).then(res => {
        if (res.status === 200 && res.data.result === 0) {
          const data = res.data.data;
          const username = data.username;
          const password = data.password;

          const mqttOption = {
            clientId: data.clientId,
            username,
            password,
          }

          // @TODO: 校验 expire_time
          this.mqttState = new UranusMqtt(data.address, mqttOption);
          console.log("fetchStationDecConnect data value", this.mqttState)
          if(this.mqttState != null) {

            this.mqttState.initMqtt()
            this.mqttState.on('onStatus', (statusOptions) => {
              console.log("fetchStationDecConnect statusOptions", statusOptions)
              // @TODO: 异常case
            })
          }
          store.dispatch("station/SET_MQTT_STATE", this.mqttState)
          store.dispatch("station/SET_CLIENT_ID", data.clientId)
        }
      })
      // @TODO: 认证失败case
      return
    }

    if (this.mqttState) {
      this.mqttState.destroyed()
      this.mqttState = null
      store.dispatch("station/SET_MQTT_STATE", null)
      store.dispatch("station/SET_CLIENT_ID", null)
    }
  }

  onUnmounted = (() => {
    this.mqttState?.destroyed()
  })
}

export default UseConnectMqtt;
