import EventBus from '@/station/event-bus'
import {
  DRC_METHOD,
  // DRCHsiInfo,
  // DRCOsdInfo,
  // DRCDelayTimeInfo,
  // DrcResponseInfo,
} from '@/station/type/drc'


class UseDroneControlMqttEvent {
  drcInfo = null
  hsiInfo = null
  osdInfo = null
  delayInfo = null
  errorInfo = null

  sn = null

  handleHsiInfo (data) {
    this.hsiInfo = `method: ${DRC_METHOD.HSI_INFO_PUSH}\r\n ${JSON.stringify(data)}\r\n `
  }

  //data = DRCOsdInfo
  handleOsdInfo (data){
    this.osdInfo = `method: ${DRC_METHOD.OSD_INFO_PUSH}\r\n ${JSON.stringify(data)}\r\n `
  }

  //data = DRCDelayTimeInfo
  handleDelayTimeInfo (data) {
    this.delayInfo = `method: ${DRC_METHOD.DELAY_TIME_INFO_PUSH}\r\n ${JSON.stringify(data)}\r\n `
  }

  //data = DrcResponseInfo
  handleDroneControlErrorInfo (data) {
    if (!data.result) {
      return
    }
    this.errorInfo = `Drc error code: ${data.result}, seq: ${data.output?.seq}`
  }

  handleDroneControlMqttEvent = function(payload) {
    console.log('handleDroneControlMqttEvent', payload)
    if (!payload || !payload.method) {
      return
    }

    switch (payload.method) {
      case DRC_METHOD.HSI_INFO_PUSH: {
        this.handleHsiInfo(payload.data)
        break
      }
      case DRC_METHOD.OSD_INFO_PUSH: {
        this.handleOsdInfo(payload.data)
        break
      }
      case DRC_METHOD.DELAY_TIME_INFO_PUSH: {
        this.handleDelayTimeInfo(payload.data)
        break
      }
      case DRC_METHOD.DRONE_EMERGENCY_STOP:
      case DRC_METHOD.DRONE_CONTROL: {
        this.handleDroneControlErrorInfo(payload.data)
        break
      }
    }
    this.drcInfo = this.hsiInfo + this.osdInfo + this.delayInfo
  }

  onMounted = (() => {
    EventBus.on('droneControlMqttInfo', this.handleDroneControlMqttEvent)
  })

  onUnmount = (() => {
    EventBus.off('droneControlMqttInfo', this.handleDroneControlMqttEvent)
  })
}

export default UseDroneControlMqttEvent;
