// import { onMounted, onUnmounted } from 'vue'
import ConnectWebSocket from '@/station/websocket'
import { getWebsocketUrl } from '@/station/websocket/util/config.js'

class UseConnectWebSocket {
  webSocket = null
  messageHandler = null

  constructor() {
    this.webSocket = null
    this.messageHandler = null
  }

  onConnectWebSocket(_messageHandler, eventId) {
    this.messageHandler = _messageHandler
    this.webSocket = new ConnectWebSocket(getWebsocketUrl(eventId))
  }

  onWebSocketMounted = (() => {
    if(this.webSocket != null) {
      this.webSocket.registerMessageHandler(this.messageHandler)
      this.webSocket.initSocket()
    }
  })

  onWebSocketUnmounted = (() => {
    if (this.webSocket != null) {
      this.webSocket.close()
    }
  })
}
/**
 * 메시지 수신 기능
 * @param messageHandler
 */

export default UseConnectWebSocket

