import {DRC_METHOD,} from '@/station/type/drc'
import EventBus from '@/station/event-bus'
import store from "@/store";

class UseMqtt {
  deviceTopicInfo = null
  mqttState = null
  heartState = new Map();
  heartBeatSeq = 0
  cacheSubscribeArr = []

  setStoreMqttState(deviceTopicInfo) {
    this.mqttState = store.getters["station/GET_MQTT_STATE"]
    this.deviceTopicInfo = deviceTopicInfo
    console.log('setStoreMqttState', deviceTopicInfo)
    console.log('setStoreMqttState', this.mqttState)

    if (this.deviceTopicInfo.subTopic !== '') {
      // 1.订阅topic
      this.subscribeMqtt(this.deviceTopicInfo.subTopic)
      // 2.发心跳
      this.publishDrcPing(this.deviceTopicInfo.sn)
    } else {
      clearInterval(this.heartState.get(this.deviceTopicInfo.sn)?.pingInterval)
      this.heartState.delete(this.deviceTopicInfo.sn)
      this.heartBeatSeq = 0
    }
  }

  publishMqtt (topic, body, ots) {
    console.log('useMqtt publishMqtt', topic, JSON.stringify(body), ots)
    this.mqttState.publishMqtt(topic, JSON.stringify(body), ots)
  }

  subscribeMqtt(topic, handleMessageMqtt) {
    console.log('useMqtt subscribeMqtt', this.mqttState, topic, handleMessageMqtt)
    const handler = handleMessageMqtt || this.onMessageMqtt
    console.log('useMqtt subscribeMqtt', topic, handler)
    this.mqttState?.on('onMessageMqtt', handler)
    this.cacheSubscribeArr.push({topic, callback: handler,})
  }

  publishDrcPing(sn) {
    const body = {
      method: DRC_METHOD.HEART_BEAT,
      data: {
        ts: new Date().getTime(),
        seq: this.heartBeatSeq,
      },
    }
    const pingInterval = setInterval(() => {
      if (!this.mqttState) return
      this.heartBeatSeq += 1
      body.data.ts = new Date().getTime()
      body.data.seq = this.heartBeatSeq
      this.publishMqtt(this.deviceTopicInfo.pubTopic, body, { qos: 0 })
    }, 1000)
    this.heartState.set(sn, {pingInterval,})
  }

  onMessageMqtt (message) {
    console.log('onMessageMqtt', message)
    if (this.cacheSubscribeArr.findIndex(item => item.topic === message?.topic) !== -1) {
      const payloadStr = new TextDecoder('utf-8').decode(message?.payload)
      const payloadObj = JSON.parse(payloadStr)
      switch (payloadObj?.method) {
        case DRC_METHOD.HEART_BEAT:
          break
        case DRC_METHOD.DELAY_TIME_INFO_PUSH:
        case DRC_METHOD.HSI_INFO_PUSH:
        case DRC_METHOD.OSD_INFO_PUSH:
        case DRC_METHOD.DRONE_CONTROL:
        case DRC_METHOD.DRONE_EMERGENCY_STOP:
          EventBus.emit('droneControlMqttInfo', payloadObj)
          break
        default:
          break
      }
    }
  }
  unsubscribeDrc () {
    // 销毁已订阅事件
    this.cacheSubscribeArr.forEach(item => {
      this.mqttState?.off('onMessageMqtt', item.callback)
      this.mqttState?.unsubscribeMqtt(item.topic)
    })
    this.cacheSubscribeArr = []
  }

  onUnmounted = (() => {
    this.unsubscribeDrc()
    this.heartBeatSeq  = 0
  })
}

export default UseMqtt;
