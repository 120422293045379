// import {IClientOptions,} from 'mqtt'

// export const OPTIONS: IClientOptions = {
export const OPTIONS = {
  clean: true, // true: 세션 지우기, false: 세션 유지
  connectTimeout: 10000, // mqtt 시간 초과
  resubscribe: true, // 연결을 끊었다가 다시 연결한 후 원래 구독을 다시 구독하세요.断开重连后，再次订阅原订阅
  reconnectPeriod: 10000, // 재연결 간격: 5초
  keepalive: 1, // 하트비트 간격: 1초
}
