import EventBus from '@/station/event-bus'
import { EBizCode } from "@/api/station/dji/enums";
import { ControlSource } from '@/station/type/device'
import { ControlSourceChangeType, ControlSourceChangeInfo, FlyToPointMessage, TakeoffToPointMessage, DrcModeExitNotifyMessage, DrcStatusNotifyMessage } from '@/station/type/drone-control'

class UseDroneControlWsEvent {
    sn = null
    payloadSn = null
    funcs = null

    droneControlSource = ControlSource.A
    payloadControlSource = ControlSource.B

    constructor(_sn ,payloadSn, funcs) {
      this.sn = _sn
      this.payloadSn = payloadSn
      this.funcs = funcs
    }

  onControlSourceChange(data) {
    console.log("onControlSourceChange", data)
    let controlSourceChangeInfo = new ControlSourceChangeInfo(data.sn, data.type, data.control_source)
    if (data.type === ControlSourceChangeType.Flight && controlSourceChangeInfo.sn === this.sn) {
      this.droneControlSource = data.control_source
      console.info(`Flight control is changed to ${this.droneControlSource.value}`)
      return
    }
    if (data.type === ControlSourceChangeType.Payload && controlSourceChangeInfo.sn === this.payloadSn) {
      this.payloadControlSource = data.control_source
      console.info(`Payload control is changed to ${this.payloadControlSource.value}.`)
    }
  }

  handleProgress (key, message, error) {
    if (error !== 0) {
      console.error({
        key: key,
        message: key + 'Error code:' + error,
        description: message,
        duration: null
      })
      // notification.error({
      //   key: key,
      //   message: key + 'Error code:' + error,
      //   description: message,
      //   duration: null
      // })
    } else {
      console.info({
        key: key,
        message: key,
        description: message,
        duration: 30
      })
    }
  }
  handleDroneControlWsEvent(payload) {
    if (!payload) {
      return
    }

    console.log("handleDroneControlWsEvent", payload)
    let payloadData = payload.data;
    switch (payload.biz_code) {
      case EBizCode.ControlSourceChange: {
        this.onControlSourceChange(payload.data)
        break
      }
      case EBizCode.FlyToPointProgress: {
        let flyToPointMessage = new FlyToPointMessage(payloadData.sn, payloadData.result, payloadData.message)
        if (flyToPointMessage.sn !== this.sn) return
        this.handleProgress(EBizCode.FlyToPointProgress, `device(sn: ${flyToPointMessage.sn}) ${flyToPointMessage.message}`, flyToPointMessage.result)
        break
      }
      case EBizCode.TakeoffToPointProgress: {
        let takeoffToPointMessage = new TakeoffToPointMessage(payloadData.sn, payloadData.result, payloadData.message)
        if (takeoffToPointMessage.sn !== this.sn) return
        this.handleProgress(EBizCode.TakeoffToPointProgress, `device(sn: ${takeoffToPointMessage.sn}) ${takeoffToPointMessage.message}`, takeoffToPointMessage.result)
        break
      }
      case EBizCode.JoystickInvalidNotify: {
        let dcModeExitNotifyMessage = new DrcModeExitNotifyMessage(payloadData.sn, payloadData.result, payloadData.message)
        if (dcModeExitNotifyMessage.sn !== this.sn) return
        this.handleProgress(EBizCode.JoystickInvalidNotify, `device(sn: ${dcModeExitNotifyMessage.sn}) ${dcModeExitNotifyMessage.message}`, dcModeExitNotifyMessage.result)
        break
      }
      case EBizCode.DrcStatusNotify: {
        let drcStatusNotifyMessage = new DrcStatusNotifyMessage(payloadData.sn, payloadData.result, payloadData.message)
        drcStatusNotifyMessage;
        // handleProgress(EBizCode.DrcStatusNotify, `device(sn: ${deviceSn}) ${msg}`, result)
        break
      }
    }
    // eslint-disable-next-line no-unused-expressions
    // console.log('payload.biz_code', payload.data)
  }

  onMounted = (() => {
    EventBus.on('droneControlWs', this.handleDroneControlWsEvent)
  })

  onUnmount = (() => {
    EventBus.off('droneControlWs', this.handleDroneControlWsEvent)
  })
}

export default UseDroneControlWsEvent
