export const DRC_METHOD = Object.freeze({
  "HEART_BEAT" : 'heart_beat',
  "DRONE_CONTROL" : 'drone_control', // 비행 제어-가상 조이스틱
  "DRONE_EMERGENCY_STOP" : 'drone_emergency_stop', // 비상 정지
  "OSD_INFO_PUSH" : 'osd_info_push', // OSD 정보 Push
  "HSI_INFO_PUSH" : 'hsi_info_push', // his 정보 Push
  "DELAY_TIME_INFO_PUSH" : 'delay_info_push', // 영상 전송 링크 지연 정보 Push
})

// 手动控制
export class DroneControlProtocol {
  constructor() {
    this.x = null;
    this.y = null;
    this.h = null;
    this.w = null;
    this.seq = null;
  }
}

export class CameraZoom {
    constructor() {
        this.camera_type = null;
        this.payload_index = null;
        this.zoom_factor = null;
    }
}

export class CameraScreenSplit{
    constructor() {
        this.eanble = null;
        this.payload_index = null;
    }
}

// 低延时osd
export class DRCOsdInfo {
  constructor() {
    this.attitude_head = 0; //항공기 자세 헤드 각도, 단위: 도
    this.latitude = 0; //항공기 위도
    this.longitude = 0; //항공기 경도
    this.altitude = 0; //항공기 높이
    this.speed_x = 0; //항공기 속도 pitch
    this.speed_y = 0; //항공기 속도 roll
    this.speed_z = 0; //항공기 속도 yaw
    this.gimbal_pitch = 0; //항공기 짐로 pitch
    this.gimbal_roll = 0; //항공기 짐로 roll
    this.gimbal_yaw = 0; //항공기 짐로 yaw
  }
}

// 态势感知-HSI
export class DRCHsiInfo {
  constructor() {
    this.up_distance = -1;// 위의 장애물까지의 거리, 단위: mm
    this.down_distance= -1;// 아래 장애물까지의 거리, 단위: mm
    this.around_distances = []; // 수평 관측점은 장애물과 항공기 사이의 거리(mm)를 나타내는 간격 [0, 360)에 분포되어 있습니다. 0은 항공기의 기수 방향에 해당하며 시계 방향으로 분포됩니다. 예를 들어 0도는 항공기의 기수 바로 앞이고 90도는 항공기의 바로 오른쪽입니다.
    this.up_enable = null; // 상향 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.up_work = null; // 상향 시야 장애물 회피 작동 상태, 참: 정상 작동, 거짓: 비정상 또는 오프라인
    this.down_enable = null; // 하향 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.down_work = null; // 하향 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.left_enable = null; // 좌측 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.left_work = null; // 좌측 시야 장애물 회피 작동 상태, 참: 정상 작동, 거짓: 비정상 또는 오프라인
    this.right_enable = null; // 우측 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.right_work = null; // 오른쪽 시야 장애 회피 작업 상태, true: 정상 작동, false: 비정상 또는 오프라인
    this.front_enable = null; // 전방 시야 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.front_work = null; // 전방 장애물 회피 작동 상태, 참: 정상 작동, 거짓: 비정상 또는 오프라인4j
    this.back_enable = null; // 후방 장애물 회피 스위치 상태, true: 켜짐, false: 꺼짐
    this.back_work = null; // 후면 장애물 회피 작동 상태, 참: 정상 작동, 거짓: 비정상 또는 오프라인
    this.vertical_enable = null; // 수직 방향 종합 스위치 상태. 이 프로토콜의 업 및 다운 스위치 상태가 모두 참이면 참을 출력하고, 그렇지 않으면 거짓을 출력합니다.
    this.vertical_work = null; // 수직 장애물 회피 작업 상태. 이 프로토콜의 위아래 보기 작업이 모두 참이면 출력은 참입니다: 정상 작동이고, 그렇지 않으면 출력은 거짓입니다: 비정상 또는 오프라인입니다.
    this.horizontal_enable = null; // 수평 방향의 포괄적인 스위치 상태. 이 프로토콜의 전면, 후면, 왼쪽, 오른쪽 및 스위치 상태가 모두 true이면 출력이 true입니다. 그렇지 않으면 출력이 false입니다.
    this.horizontal_work = null; // 水平方向避障工作综合状态，当本协议中前、后、左、右视工作均为true时，输出true：正常工作，否则输出false：异常或离线
  }

}

export class LiveViewDelayItem {
  constructor() {
    this.video_id = null;
    this.liveview_delay_time = -1;
  }
}

// 链路时延信息
export class DRCDelayTimeInfo {
  constructor() {
    this.sdr_cmd_delay = -1;
    this.liveview_delay_list = []; // liveview_delay_time
  }

  addLiveViewDelayItem = function(liveViewDelayItem) {
    liveViewDelayItem;
    this.push(LiveViewDelayItem);
  }
}

export class DrcResponseInfo {
    constructor() {
        this.result = null;
        this.output = {
          seq: null
        }
    }
}
