import axios from "axios";
import { StationBaseUrl, baseApiVersion,StationBaseApiPreFix, headers } from "../../config/env";

export function fetchStationDecConnect(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/connect`, params, {headers});
}

export function fetchStationDecEnter(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/enter`, params, {headers});
}

export function fetchStationDecExit(params) {
    return axios.post(`${StationBaseUrl}${baseApiVersion}${StationBaseApiPreFix}/device/drc/exit`, params, {headers});
}
